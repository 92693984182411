import React from "react"
import ContactForm from "../components/ContactForm"
import SEO from "../components/SEO"
import { ContentHeading, ContentSubHeading } from "../components/styles"
import Layout from "../components/Layout"

export default function ContactSales() {
  return (
    <>
      <Layout>
        <>
          <SEO title={"Request Demo | Pricelist"} />
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-prose text-lg">
              <h1>
                <ContentSubHeading>Get in Touch!</ContentSubHeading>
                <ContentHeading>Request Pricelist Demo</ContentHeading>
                <ContactForm cta="Schedule a demo" />
              </h1>
            </div>
          </div>
        </>
      </Layout>
    </>
  )
}
